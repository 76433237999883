import React from 'react'
import ParticlesBg from 'particles-bg'
import {Card, Container, Row, Col, Button, Carousel} from 'react-bootstrap'
// import { Document, Page} from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// import resume from './Atharva_Kadam_Resume.pdf'
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import CardComponent from './CardComponent';
import WireFramerImg from '../Image/wireframer_resized.jpeg'
import CosmosImg from '../Image/cosmos_resized.jpeg'
import DeliveryBoyImg from '../Image/delivery_resized.jpeg'

const Projects = () => {

    const cosmos = {
        name: 'Cosmos',
        badges: ['React.js', 'Node.js', 'Express.js', 'MongoDB', 'HTML', 'CSS'],
        description: 'An Online Shopping service made using the MERN stack built to elevate the shopping experience of every online shopper',
        github: 'https://github.com/atharvakadam/Cosmos',
        image:CosmosImg
    }

    const deliveryBoy = {
        name: 'Delivery Boy',
        badges: ['React-Native', 'iOS', 'Firebase', 'XCode', 'Javascript'],
        description: 'An iOS food delivery app made using React-Native and a Firebase backend for better and more streamlined online food delivery.',
        github:'https://github.com/atharvakadam/DeliveryBoy',
        image:DeliveryBoyImg
    }

    const wireFramer = {
        name: 'WireFramer',
        badges: ['React.js', 'Node.js', 'Express.js', 'MongoDB', 'HTML', 'CSS'],
        description: 'A wireframing tool built with a React frontend and a backend powered by Firebase which lets you create unique designs',
        github: 'https://github.com/atharvakadam/WireFramer',
        image: WireFramerImg
    }

    return (
        <div id="Projects">
            <div style={{backgroundColor:'#white', borderRadius:'0px', minHeight:'100vh'}}>
            <Container>
                <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}><strong style={{fontSize:'25px', color:'#343a40', padding: '50px'}}>PROJECTS</strong></Row>
                <Row> 
                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <CardComponent project={cosmos}></CardComponent>
                        <br></br>
                    </Col>
                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <CardComponent project={deliveryBoy}></CardComponent>
                        <br></br>
                    </Col>
                    <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <CardComponent project={wireFramer}></CardComponent>
                        <br></br>
                    </Col>  
                </Row>
                
            </Container>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '50px', paddingBottom:'50px'}}><Button className="button" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="https://github.com/atharvakadam" target="_blank"><span>View More </span></Button></div>
            </div>
        </div>
            
    )
}



export default Projects

// <Carousel style={{width:'1000px',height:'600px'}} pause="hover" wrap={false}>
//                         <Carousel.Item>
//                             <img
//                                 className="d-block w-100"
//                                 src={CosmosImg}
//                                 alt="First slide"
                                
//                             />
//                             <Card>
//                             <Card.Body style={{textAlign:'center'}}>
//                                 <Card.Title>WireFramer</Card.Title>
//                                 <Card.Subtitle>A wireframing tool</Card.Subtitle>
//                                 <Card.Text>Hellooo</Card.Text>
//                             </Card.Body>
//                             </Card>
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <img
//                                 className="d-block w-100"
//                                 src={DeliveryBoyImg}
//                                 alt="Third slide"
//                             />
//                             <Card>
//                             <Card.Body style={{textAlign:'center'}}>
//                                 <Card.Title>WireFramer</Card.Title>
//                                 <Card.Subtitle>A wireframing tool</Card.Subtitle>
//                                 <Card.Text>Hellooo</Card.Text>
//                             </Card.Body>
//                             </Card>
//                         </Carousel.Item>
//                         <Carousel.Item >
//                             <img
//                                 className="d-block w-100"
//                                 src={WireFramerImg}
//                                 alt="Third slide"
//                             />

//                             <Card>
//                             <Card.Body style={{textAlign:'center'}}>
//                                 <Card.Title>WireFramer</Card.Title>
//                                 <Card.Subtitle>A wireframing tool</Card.Subtitle>
//                                 <Card.Text>Hellooo</Card.Text>
//                             </Card.Body>
//                             </Card>
//                         </Carousel.Item>
                        
//                     </Carousel>
