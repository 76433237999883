import React from 'react'

import {Image, Button, Card, Container, Row, Col, Fade} from 'react-bootstrap'
import Atharva from '../Image/Atharva.jpeg'
import '../css/App.css'
// import ParticlesBg from 'particles-bg'
import Typing from 'react-typing-animation';
import PDF from './Atharva_Kadam_Resume.pdf'

const About = () => {
    return (
            <div id="About">
            <div style={{backgroundColor:'#343a40', borderRadius:'0px', minHeight:'100vh'}}>
            <Container>
                <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}><strong style={{fontSize:'25px', color:'white', padding: '30px'}}>ABOUT ME</strong></Row>
                <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Col >
                        <br></br>
                        <Card style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <br></br>
                            <Row>
                                <Image src={Atharva} style={{display:'block',marginLeft: 'auto', marginRight: 'auto', width:'340'}} roundedCircle />
                            </Row>
                            <br></br>
                            <strong style={{fontSize:'20px', color:"#343a40"}} >Atharva Kadam</strong>
                            <span style={{fontSize:'20px', textAlign:'center', color:"#343a40"}} >BS-MS in Computer Science at Stony Brook Univeristy</span>
                            <br></br>
                            <div style={{paddingBottom:'20px'}}>
                                <span style={{padding:'5px'}}><Button variant="outline-dark" style={{width:'90px'}} href={PDF} target="_blank">Resume</Button></span>
                                <span style={{padding:'5px'}}><Button variant="outline-dark" style={{width:'90px'}} target="_blank" href="https://github.com/atharvakadam">GitHub</Button></span>
                                <span style={{padding:'5px'}}><Button variant="outline-dark" style={{width:'90px'}} target="_blank" href="https://atharva.uncleand.us/posts/">Blog</Button></span>
                            </div>
                        </Card>
                        <br></br>
                        <br></br>
                        <br></br>
                    </Col>
                </Row>
                <Row style={{paddingBottom:'30px'}}>
                <Col>
                <div style={{backgroundColor:'#343a40'}}>
                            
                            <p style={{fontSize:'20px',color:'white'}}>
                            Hello there! I am a senior studying Computer Science at Stony Brook University. 
                            I am passionate about technology and currently interested in Full-Stack Development, Data Science, and Machine Learning. 
                            I have participated in various projects during my time at Stony Brook University and I look forward to working on interesting projects.
                            </p> 
                            <p style={{fontSize:'20px',color:'white'}}>
                            My journey in Computer Science started in 2017, when I first came to the United States; where I got the chance to participate on a research team at my university. 
                            It not only helped me improve my hands on skills but also gave me a new perception of my interests and strengthened my interest in Computer Science.
                            </p> 
                            <p style={{fontSize:'20px',color:'white'}}>
                            I am currently a Full-Stack Developer and I love building end-to-end web and mobile applications. 
                            I also love doing exploratory data analysis on the side.
                            </p>
                            
                        </div>
                        </Col>
                </Row>
                <br></br>
                <br></br>
            </Container>
            </div>
            </div>
        
    )
}

export default About

