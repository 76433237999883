import React from 'react'
import {Button, Navbar, Nav, Container, Jumbotron} from 'react-bootstrap'
import Typing from 'react-typing-animation';
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import Typist from 'react-typist';
import {Scroll} from 'react-scroll'
import '../css/App.css'
import ParticlesBg from 'particles-bg'


const Home = (props) => {
    return (
            <div style={{minHeight:'100vh'}} id="Home">
                <ParticlesBg type="cobweb" color="#343a40" bg={true}></ParticlesBg>
                <h1 style={{fontSize:'85px', textAlign:'center', alignSelf:'center', paddingTop:'20%', color:'#343a40', paddingBottom:'5px'}}>Atharva Kadam </h1>
                <div style={{alignItems:'center',justifyContent:'center', textAlign:'center'}}>
                <Typing speed={1} loop>
                    <span style={{paddingBottom:'10%',fontSize:'25px' }} >Full Stack Developer</span>
                    <Typing.Delay ms={1000} />
                    <Typing.Backspace count={25} />
                    <span style={{paddingBottom:'10%',fontSize:'25px' }}>Data Scientist</span>
                    <Typing.Delay ms={1000} />
                    <Typing.Backspace count={25} />
                    <span style={{paddingBottom:'10%',fontSize:'25px' }}>Software Engineer</span>
                    <Typing.Delay ms={1000} />
                    <Typing.Backspace count={25} />
                </Typing>
                <br></br>
                <Button variant="dark" className="learnMore" style={{ width:'120px', borderRadius:'20px', borderColor:'#343a40'}} onClick={() =>props.getScrollNumber('About')}>Learn More</Button>
                </div>
            </div>
           
    )
}
// style={{backgroundColor:'#343a40' , width:'100px', borderRadius:'20px', borderColor:'white'}}
export default Home
