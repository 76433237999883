import React from 'react'
import {Card} from 'react-bootstrap'

const Footer = () => {
    return (
        <div id="Projects" >
            <Card style={{alignItems:'center', marginLeft:'auto', marginRight:'auto',backgroundColor:'#343a40', color:'white', borderRadius:'0px'}}>
                <Card.Body>
                    <Card.Text style={{textAlign:'center'}}>Built with ♥️ and React.js by Atharva Kadam © </Card.Text>
                </Card.Body>
            </Card>      
        </div>
            
    );
}

export default Footer