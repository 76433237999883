import React, { useState } from 'react';
import './css/App.css';
import About from './components/About'
import Projects from  './components/Projects'
import Contact from './components/Contact'
import Home from './components/Home'
import Footer from './components/Footer'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
//#546de5
import {Button, Navbar, Nav, Container, Jumbotron,NavItem} from 'react-bootstrap'
import ParticlesBg from 'particles-bg'
import { Link, animateScroll as scroll } from "react-scroll";
// import {Link} from 'react-scroll'
// import ReactPageScroller from "react-page-scroller";

let goToPage = (pageNumber) => {
  this.reactPageScroller.goToPage(pageNumber);
}

const padding = {
  padding: 5,
  color: 'white'
}




const App = () => {

  const [currentPage, setCurrentPage] = useState('1');

  const handlePageChange = (number) => {
    setCurrentPage(number)
  }

  var getScrollNumber = (Id) => {
    var num = document.getElementById(Id).offsetTop
    var navbarHeight = document.getElementById('navbar').clientHeight
    console.log("offset height",num)
    console.log("Navbar",navbarHeight)
    scroll.scrollTo(num - 56);
  }

  return (
    <div className="root-class">
    
    <Navbar id="navbar" fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">Atharva Kadam</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">

            </Nav>
            <Nav>
              <Nav.Link href="#Home" as="span">
              {/*<Link style={padding} to="/">Home</Link>
                */}
                <a style={padding} href="#" onClick={()=>scroll.scrollToTop()}>Home</a>
              </Nav.Link>
              <Nav.Link href="#About" as="span"> 
                <a style={padding} href="#About" onClick={() => getScrollNumber('About')}>About</a>
                {/*<Link style={padding} to="/About">About</Link>
                */}
              </Nav.Link>
              <Nav.Link href="#Projects" as="span">
                <a style={padding} href="#Projects" onClick={() => getScrollNumber('Projects')}>Projects</a>
                {/*<Link style={padding} to="/Projects">Projects</Link>
                */}
              </Nav.Link>
              <Nav.Link href="#Contact" as="span">
                <a style={padding} href="#Contact" onClick={()=>scroll.scrollToBottom()}>Contact</a>
                {/*<Link style={padding} to="/Contact">Contact</Link>
                */}
              </Nav.Link>
              <a href="https://atharva.uncleand.us/posts/" target="_blank" style={{padding:'8px',color:'white'}}>Blog</a>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    <Home getScrollNumber={getScrollNumber}/>
    <About />
    <Projects />
    <Contact />
    <Footer />
    </div>
  
  
  
  );
}


export default App;


// <Router>
      
//       <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
//           <Navbar.Brand href="/">Atharva Kadam</Navbar.Brand>
//           <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//             <Navbar.Collapse id="responsive-navbar-nav">
//               <Nav className="mr-auto">
  
//               </Nav>
//               <Nav>
//                 <Nav.Link href="#Home" to="/" as="span">
//                 {/*<Link style={padding} to="/">Home</Link>
//                   */}
                  
//                   <a style={padding} i href="/">Home</a>
//                 </Nav.Link>
//                 <Nav.Link href="#About" as="span">
                  
//                   <a style={padding} href="/About"><Link to="About">About</Link></a>
//                   {/*<Link style={padding} to="/About">About</Link>
//                   */}
//                 </Nav.Link>
//                 <Nav.Link href="#Projects" as="span">
//                   <a style={padding} href="/Projects">Projects</a>
//                   {/*<Link style={padding} to="/Projects">Projects</Link>
//                   */}
//                 </Nav.Link>
//                 <Nav.Link href="#Contact" as="span">
//                   <a style={padding} href="/Contact">Contact</a>
//                   {/*<Link style={padding} to="/Contact">Contact</Link>
//                   */}
//                 </Nav.Link>
//                 <a href="https://blog.atharvakadam.com" target="_blank" style={{padding:'8px',color:'white'}}>Blog</a>
//               </Nav>
//           </Navbar.Collapse>
//       </Navbar>
      
//       <Switch>
//          <Route path="/Projects">
//            <Projects />
//          </Route>
//          <Route path="/Contact">
//            <Contact />
//          </Route>
//          <Route path="/About">
//             <About />
//          </Route>
//          <Route path="/">
//           <ParticlesBg type="cobweb" color="#343a40" bg={true}></ParticlesBg>
//           <Home />
//           <About />
//           <Projects />
//           <Contact />
//          </Route>
//        </Switch>
      
//       </Router>