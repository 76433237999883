import React from 'react'
import {Card, Badge, Button} from 'react-bootstrap'
import Image from '../Image/Atharva.jpeg'

const CardComponent = (props) => {
    return (
        
            <Card className="wrapper" style={{width:'350px', height: '660px', padding:'5px', boxShadow:'1px 1px 8px grey'}}>
                <Card.Img src={props.project.image} height="340px" style={{borderWidth:'5px', borderColor:'grey'}}/>
                <Card.Body  style={{backgroundColor:'white', color:'#343a40',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Card.Title style={{textAlign:'center'}}>{props.project.name}</Card.Title>
                    <Card.Subtitle  className="overlay" 
                        style={{ textAlign:'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textEmphasisColor:'white'}}>
                        {props.project.badges.map(function(i){
                            return <span style={{padding: '3px'}}><Badge pill variant="light" style={{opacity:1}}>{i}</Badge></span>
                        })}
                        
                    </Card.Subtitle>
                    <Card.Text style={{textAlign:'center',color:'#343a40'}}>
                        {props.project.description}
                    </Card.Text>
                    <div style={{textAlign:'center', paddingTop:'10px', paddingBottom:'10px'}}><Button variant="outline-dark" style={{width:'100px'}} href={props.project.github} target="_blank" >Github</Button></div>
                    
                </Card.Body>
            </Card>  
       
    )
}

export default CardComponent


// <div className="wrapper">
// <img src={Image}></img>
//                 <div className="overlay">
//                     <div class="content">Image Hover Effect</div>
//                 </div>
// </div>