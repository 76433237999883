import React from 'react'
import {Image, Button, Card, Container, Row, Col} from 'react-bootstrap'
import Github from '../Image/icons8-github-48.png'
import Gmail from '../Image/icons8-gmail-48.png'
import Linkedin from '../Image/icons8-linkedin-48.png'
import Twitter from '../Image/icons8-twitter-48.png'

import {LinkedinShareButton, TwitterShareButton} from 'react-share'
import { Redirect } from 'react-router-dom'


const Contact = () => {
    return (
        <div style={{width:'100%', height:'320px',backgroundColor:'#343a40', color:'white',
                    fontSize: '65px',display: 'flex', flexDirection: 'column', alignItems: 'center', 
                    justifyContent: 'center' }} id="Contact">
            <div style={{textAlign:'center'}}>
                <div>Get in touch.</div>
                <div style={{padding:'30px'}}>
                    <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="https://linkedin.com/in/atharvakadam" target="_blank"><Image src={Linkedin} width="5px" ></Image></Button>
                    <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="https://github.com/atharvakadam" target="_blank"><Image src={Github} ></Image></Button>
                    <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="mailto:atharvakadam@gmail.com" target="_blank"><Image src={Gmail} ></Image></Button>
                    <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="https://twitter.com/atharvakadam2" target="_blank"><Image src={Twitter} ></Image></Button>
                </div>
            </div>
        </div>
    )
}

export default Contact

//Get in touch - style={{top:'50%', textAlign:'center', paddingTop:'18%', fontSize:'65px'}}

// <Card style={{height:'300px',width:'100%', backgroundColor:'#343a40', color:'white'}}>

// style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}


// <Container style={{width:'100%', height:'320px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
//                     <Row> 
//                         <Col>
//                             <div style={{fontSize:'65px', textAlign:'center'}}><p>Get in touch</p></div>
//                         </Col>
//                         <Col></Col>
//                         <Col>
//                             <Row >
//                                 <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="https://linkedin.com/in/atharvakadam" target="_blank"><Image src={Linkedin} ></Image></Button>
//                                 <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="https://github.com/atharvakadam" target="_blank"><Image src={Github} ></Image></Button>
//                                 <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="mailto:atharvakadam@gmail.com" target="_blank"><Image src={Gmail} ></Image></Button>
//                                 <Button className="grow" style={{backgroundColor:'#343a40', borderColor:'#343a40'}} href="https://twitter.com/atharvakadam2" target="_blank"><Image src={Twitter} ></Image></Button>
//                             </Row>
//                         </Col>
//                     </Row>
//             </Container>